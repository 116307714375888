import axiosInstance from '../api/axiosInstance'

const todosAPI = {
  getAll(currentYear) {
    return axiosInstance.get('/main/todo?year='+currentYear)
  },
  post(body){
    return axiosInstance.post(`/main/todo`, body)
  },

  patch(id, body){
    return axiosInstance.patch(`/main/todo/`+id, body)
  },

  delete(id){
    return axiosInstance.delete(`/main/todo/`+id)
  },

}

export default todosAPI