export const LOGIN_POST = 'AUTH_LOGIN_POST'
export const LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const LOGIN_ERROR = 'AUTH_LOGIN_ERROR'
export const LOGOUT = 'AUTH_LOGOUT'

export const PASSWORD_FORGOT_POST = 'AUTH_PASSWORD_FORGOT_POST'
export const PASSWORD_FORGOT_SUCCESS = 'AUTH_PASSWORD_FORGOT_SUCCESS'
export const PASSWORD_FORGOT_ERROR = 'AUTH_PASSWORD_FORGOT_ERROR'

export const PASSWORD_RESET_POST = 'AUTH_PASSWORD_RESET_POST'
export const PASSWORD_RESET_SUCCESS = 'AUTH_PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_ERROR = 'AUTH_PASSWORD_RESET_ERROR'
