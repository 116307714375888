import React, { useState } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'

import LoginContainer from './views/LoginContainer'
import HomeContainer from './views/home/HomeContainer'
import Snackbar from './commons/Snackbar'

function App({ isLoggedIn, location }) {
    const shouldTransition = (location.pathname === '/login') ||
      (!!location.state && location.state.from === 'login_succeed')

    const key = ['/login', '/password-forgot', '/password-reset'].includes(location.pathname) ?
      'login' :
      'home'

    const domain = window.location.hostname
    const [appVersion, setAppVersion] = useState('crm')
    // const [appVersion, setAppVersion] = useState('crm')

    return (
      <TransitionGroup>
        <Snackbar/>
        <CSSTransition
          classNames="router"
          key={key}
          timeout={2000}
          enter={shouldTransition}
          exit={shouldTransition}
        >
          <Switch location={location}>
            <Route exact path={'/login'} render={() => <LoginContainer mode="login" isLoggedIn={isLoggedIn}/>}/>
            <Route exact path={'/password-forgot'} render={() => <LoginContainer mode="password_forgot" isLoggedIn={isLoggedIn}/>}/>
            <Route exact path={'/password-reset'} render={() => <LoginContainer mode="password_reset" isLoggedIn={isLoggedIn}/>}/>
            {/*<Route path={'/r1/:projectId/:stepId'} render={() => isLoggedIn ? <R1Container/> : <Redirect to={'/login'}/>}/>*/}
            {/*<Route path={'/bdc/signature/:token'} render={() => <R1Quotation isSignature={true} />}/>*/}
            {/*<Route path={'/avenant/signature/:token'} render={() => <Amendment isSignature={true} />}/>*/}
            {/*<Route path={'/bdc/merci/:token'} render={() => <R1Success />}/>*/}
            {/*<Route path={'/avenant/merci/:token'} render={() => <UpsellSuccess />}/>*/}
            {/*<Route path={'/projets/:projectId/upsell/:upsellId'} render={() => <Amendment/>}/>*/}
            <Route path={'/'} render={() => isLoggedIn ? <HomeContainer/> : <Redirect to={'/login'}/>}/>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    )
}

App.propTypes = {
  isLoggedIn: PropTypes.bool
}

export default withRouter(App)
