import React from 'react'
import PropTypes from 'prop-types'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import * as qs from 'query-string'
import { Link } from "react-router-dom"
import { SwitchTransition, CSSTransition } from "react-transition-group"
import { withRouter } from "react-router-dom";

import logo from '../../assets/logo-greenkub.svg'
import usersAPI from '../../api/usersAPI'
import AppearDown from '../commons/transitions/AppearDown'
import { getErrorMsgFromSymfonyResponse, getCSSHeight } from '../../helpers'

class Login extends React.Component {
  constructor(props) {
    super(props)

    if (props.isLoggedIn) {
      props.history.push('/')
    }

    const query = qs.parse(window.location.search)

    this.state = {
      appName: process.env.REACT_APP_NAME,
      email: '',
      password: '',
      passwordConfirmation: '',
      token: query && query.token ? query.token : null,
      successMsg: null,
      errorMsg: props.loginErrorMsg,
      focusedField: null,
      isLoading: false,
      isPasswordVisible: false,
      isRememberMeChecked: false,
      cssCardHeight: null,
      cssPrevCardHeight: null,
      savedProps: {
        mode: props.mode,
        loginErrorMsg: props.loginErrorMsg,
        isLoggedIn: props.isLoggedIn
      }
    }

    this.cardRef = React.createRef()

    this.onLoginSubmit = this.onLoginSubmit.bind(this)
  }

  static getDerivedStateFromProps(props, oldState) {
    const newState = {}

    if (props.isLoggedIn && props.isLoggedIn !== oldState.savedProps.isLoggedIn) {
      props.history.push('/', { from: 'login_succeed' })
    }

    if (props.mode !== oldState.savedProps.mode) {
      newState.mode = props.mode
      newState.password = ''
      newState.errorMsg = null
      newState.successMsg = null
      newState.isPasswordVisible = false
      newState.isRememberMeChecked = false
    }

    if (props.loginErrorMsg !== oldState.savedProps.loginErrorMsg) {
      newState.errorMsg = props.loginErrorMsg
      newState.isLoading = false
    }

    newState.savedProps = {
      mode: props.mode,
      loginErrorMsg: props.loginErrorMsg,
      isLoggedIn: props.isLoggedIn
    }

    return newState
  }

  componentDidMount() {
    if (this.props.mode === 'password_reset' && !this.state.token) {
      this.setState({
        successMsg: null,
        errorMsg: "Token indisponible"
      })
    }
  }

  onPasswordVisibleToggle() {
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible
    })
  }

  onLoginSubmit(event) {
    event.preventDefault()

    if (this.state.email.trim().length === 0) {
      this.setState({
        successMsg: null,
        errorMsg: "L'email est requis"
      })
      return
    }

    if (this.state.password.trim().length === 0) {
      this.setState({
        successMsg: null,
        errorMsg: "Le mot de passe est requis"
      })
      return
    }

    this.setState({
      successMsg: null,
      errorMsg: null,
      isLoading: true
    })

    this.props.onLogin(this.state.email, this.state.password)
  }

  onPasswordForgotSubmit(event) {
    event.preventDefault()

    if (this.state.email.trim().length === 0) {
      this.setState({
        successMsg: null,
        errorMsg: "L'email est requis"
      })
      return
    }

    this.setState({
      successMsg: null,
      errorMsg: null,
      isLoading: true
    })

    usersAPI.postForgotPassword(
      this.state.email
    ).then(resp => {
      this.setState({
        successMsg: resp.data.message,
        errorMsg: null,
        isLoading: false
      })
    }).catch(error => {
      this.setState({
        successMsg: null,
        errorMsg: getErrorMsgFromSymfonyResponse(error.response),
        isLoading: false
      })
    })
  }

  onPasswordResetSubmit(event){
    event.preventDefault()

    if (this.state.password === '' || this.state.passwordConfirmation === ''){
      this.setState({
        successMsg: null,
        errorMsg: 'Les deux champs sont requis'
      })
      return
    }

    if (this.state.password !== this.state.passwordConfirmation){
      this.setState({
        successMsg: null,
        errorMsg: 'Les mots de passe ne sont pas identiques'
      })
      return
    }

    this.setState({
      successMsg: null,
      errorMsg: null,
      isLoading: true
    })

    usersAPI.postResetPassword(
      this.state.token,
      this.state.password
    ).then(resp => {
      this.setState({
        successMsg: resp.data.message,
        errorMsg: null,
        isLoading: false
      })
    }).catch(error => {
      this.setState({
        successMsg: null,
        errorMsg: getErrorMsgFromSymfonyResponse(error.response),
        isLoading: false
      })
    })
  }

  render() {
    return (
      <div className="Login">
        <div className="Login-container">
          <SwitchTransition mode="out-in">
            <CSSTransition
              classNames="loginmode"
              key={this.props.mode}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              onExit={() => {
                this.setState({
                  cssPrevCardHeight: getCSSHeight(this.cardRef.current, true)
                })
              }}
              onEnter={() => {
                const cssNextCardHeight = getCSSHeight(this.cardRef.current, true)
                console.log('cssNextCardHeight', cssNextCardHeight)

                this.setState({
                  cssCardHeight: this.state.cssPrevCardHeight,
                }, () => {
                  this.setState({
                    cssCardHeight: cssNextCardHeight,
                    cssPreviousCardHeight: cssNextCardHeight
                  })
                })
              }}
              onEntered={() => {
                this.setState({
                  cssCardHeight: null,
                  cssPrevCardHeight: null,
                })
              }}
            >
              <div
                className="Login-card"
                ref={this.cardRef}
                style={this.state.cssCardHeight !== null ? { height: this.state.cssCardHeight } : {}}
              >
                <span className="Login-text">✨ Welcome to <b>{this.state.appName}</b> ✨</span>

                <form
                  className="Login-form is-login"
                  onSubmit={this.onLoginSubmit}
                  style={this.props.mode !== 'login' ? { display: 'none' } : {} }
                >
                  <div className={`form-input${this.state.focusedField === 'email' ? ' is-focused' : ''}`}>
                    <input
                        type="text"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                        onFocus={() => this.setState({ focusedField: 'email' })}
                        onBlur={() => this.setState({ focusedField: null })}
                    />
                  </div>

                  <div className={`form-input${this.state.focusedField === 'password' ? ' is-focused' : ''}`}>
                    <input
                        type={this.state.isPasswordVisible === false ? 'password' : 'text'}
                        placeholder="Password"
                        onChange={e => this.setState({ password: e.target.value })}
                        onFocus={() => this.setState({ focusedField: 'password' })}
                        onBlur={() => this.setState({ focusedField: null })}
                    />
                    <button
                      className="Login-toggleVisiblePassword icon-button"
                      type="button"
                      onClick={() => this.onPasswordVisibleToggle()}
                      tabIndex="-1"
                    >
                      {this.state.isPasswordVisible === false
                        ? <Visibility></Visibility>
                        : <VisibilityOff></VisibilityOff>
                      }
                    </button>
                  </div>

                  <div className="Login-actions">
                    <Link to="/password-forgot">Password forgot ?</Link>
                  </div>

                  <AppearDown isActive={this.state.errorMsg !== null} duration=".3s">
                    <p className="Login-error form-msg is-error">{this.state.errorMsg}</p>
                  </AppearDown>

                  <button className={`Login-submit button${this.state.isLoading ? ' is-loading' : ''}`} type="submit">
                    <span>Login</span>
                  </button>
                </form>

                <form
                  className="Login-form is-reset-password"
                  onSubmit={event => this.onPasswordForgotSubmit(event)}
                  style={this.props.mode !== 'password_forgot' ? { display: 'none' } : {} }
                >
                  <div className={`form-input${this.state.focusedField === 'email' ? ' is-focused' : ''}`}>
                    <input
                        type="text"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                        onFocus={() => this.setState({ focusedField: 'email' })}
                        onBlur={() => this.setState({ focusedField: null })}
                    />
                  </div>

                  <div className="Login-actions">
                    <div><Link to="/login">Login</Link></div>
                  </div>

                  <AppearDown isActive={this.state.successMsg !== null} duration=".3s">
                    <p className="Login-success form-msg is-success">{this.state.successMsg}</p>
                  </AppearDown>

                  <AppearDown isActive={this.state.errorMsg !== null} duration=".3s">
                    <p className="Login-error form-msg is-error">{this.state.errorMsg}</p>
                  </AppearDown>

                  <button className={`Login-submit button${this.state.isLoading ? ' is-loading' : ''}`} type="submit">
                    <span>Reset password</span>
                  </button>
                </form>

                {this.props.mode === 'password_reset' &&
                  <form className="Login-form is-reset-password" onSubmit={event => this.onPasswordResetSubmit(event)}>
                    <div className={`form-input${this.state.focusedField === 'password' ? ' is-focused' : ''}`}>
                      <input
                        type={this.state.isPasswordVisible === false ? 'password' : 'text'}
                        placeholder="New password"
                        onChange={e => this.setState({ password: e.target.value })}
                        onFocus={() => this.setState({ focusedField: 'password' })}
                        onBlur={() => this.setState({ focusedField: null })}
                      />
                      <button
                        className="Login-toggleVisiblePassword icon-button"
                        type="button"
                        onClick={() => this.onPasswordVisibleToggle()}
                        tabIndex="-1"
                      >
                        {this.state.isPasswordVisible === false
                          ? <Visibility></Visibility>
                          : <VisibilityOff></VisibilityOff>
                        }
                      </button>
                    </div>

                    <div className={`form-input${this.state.focusedField === 'passwordConfirmation' ? ' is-focused' : ''}`}>
                      <input
                        type={this.state.isPasswordVisible === false ? 'password' : 'text'}
                        placeholder="Password again"
                        onChange={e => this.setState({ passwordConfirmation: e.target.value })}
                        onFocus={() => this.setState({ focusedField: 'passwordConfirmation' })}
                        onBlur={() => this.setState({ focusedField: null })}
                      />
                    </div>

                    <div className="Login-actions">
                        <Link to="/login">Login</Link>
                    </div>

                    <AppearDown isActive={this.state.successMsg !== null} duration=".3s">
                      <p className="Login-success form-msg is-success">{this.state.successMsg}</p>
                    </AppearDown>

                    <AppearDown isActive={this.state.errorMsg !== null} duration=".3s">
                      <p className="Login-error form-msg is-error">{this.state.errorMsg}</p>
                    </AppearDown>

                    <button className={`Login-submit button${this.state.isLoading ? ' is-loading' : ''}`} type="submit">
                      <span>Reset password</span>
                    </button>
                  </form>
                }
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    )
  }
}

Login.propTypes = {
  mode: PropTypes.string.isRequired,
  onLogin: PropTypes.func.isRequired,
  loginErrorMsg: PropTypes.string
}

export default withRouter(Login)
