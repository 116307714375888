import React, {createRef,useRef, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import todosAPI from "../../../api/todosAPI"

import ProgressBar from '../../commons/ProgressBar'
import withSnackbar from "../../wrappers/withSnackbar";
import StatsHeatmap from "../../commons/stats/StatsHeatmap";
import StatsMonthsDisplay from "../../commons/stats/StatsMonthsDisplay";
import {DateTime} from "luxon";
import {getErrorMsgFromSymfonyResponse} from "../../../helpers";
import daysAPI from "../../../api/daysAPI";

function Stats({snackMsg}) {

  const [isProgressBarActive, setIsProgressBarActive] = useState(true)
  const [restingData, setRestingData] = useState([])
  const [ratingData, setRatingData] = useState([])

  useEffect(() => {
    fetchDays()
  }, [])

  const fetchDays = function(){
    daysAPI.getAll().then((resp) => {
      setRestingData(resp.data.resting)
      setRatingData(resp.data.rating)
      setIsProgressBarActive(false)
    }).catch(error => {
      snackMsg('error', getErrorMsgFromSymfonyResponse(error.response))
    })
  }

  return (
    <div className="Stats">
      <ProgressBar isActive={isProgressBarActive}/>

      <div className="Stats-head card">
        <h1 className="life-h1">Statistics</h1>
      </div>

      <div className="Stats-main card" style={{marginTop : '15px'}}>
        <div className="Stats-main-heatmaps">
          <h2 className="life-h2">Daily ratings</h2>
          <StatsHeatmap width={350} height={180} fullData={ratingData} type="rating"/>
          <StatsMonthsDisplay/>
        </div>
        <div className="Stats-main-heatmaps">
          <h2 className="life-h2">Daily resting</h2>
          <StatsHeatmap width={350} height={180} fullData={restingData} type="resting"/>
          <StatsMonthsDisplay/>
        </div>

      </div>
    </div>
  )
}

Stats.propTypes = {
  user: PropTypes.object
}

export default withSnackbar(Stats)

