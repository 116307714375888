import React, { useState, createRef } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'

import { getCSSHeight } from '../../../helpers'

function AppearDown({ isActive, duration, children, className }) {
  const [height, setHeight] = useState(0)
  const [overflow, setOverflow] = useState('hidden')

  const contentRef = createRef()

  const style = {
    padding: 0,
    overflow,
    height,
    transition: `${duration} height ease`
  }

  return (
    <CSSTransition
      in={isActive}
      className="appearDown"
      addEndListener={(node, done) => {
        node.addEventListener("transitionend", done, false)
      }}
      onEnter={() => {
        setHeight(getCSSHeight(contentRef.current))
        setOverflow('hidden')
      }}
      onEntered={() => {
        //setHeight('auto')
        setOverflow('visible')
      }}
      onExit={() => {
        setHeight(0)
        setOverflow('hidden')
      }}
    >
      <div style={style}>
        {React.cloneElement(children, { ref: contentRef })}
      </div>
    </CSSTransition>
  )
}

AppearDown.propTypes = {
  children: PropTypes.element.isRequired,
  duration: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  className: PropTypes.string
}

export default AppearDown
