import AddIcon from '@material-ui/icons/AddCircle'
import React, {useState} from "react";

import TodoAddModal from "./TodoAddModal";
import {getErrorMsgFromSymfonyResponse} from "../../../helpers";
import todosAPI from "../../../api/todosAPI"
import {DateTime} from "luxon";
import { useForm } from 'react-hook-form'
import withSnackbar from "../../wrappers/withSnackbar";
import {Link} from "react-router-dom";

function TodoSummary({handleClick, snackMsg}) {

  const {control, reset} = useForm()

  const [addModalOpen, setAddModalOpen] = useState(false)

  const addTodoSubmit = function (newTodoName, newTodoDate, newTodoCat) {

    todosAPI.post({
        name : newTodoName,
        estimated_date : newTodoDate,
        category : newTodoCat,
      }).then(resp => {
      setAddModalOpen(false)

      snackMsg('success', "Todo saved")
    }).catch(error => {
      snackMsg('error', getErrorMsgFromSymfonyResponse(error.response))
    })
  }

  return (
      <div onClick={handleClick} className="TodoSummary card">
        <div className="TodoSummary-header">
          <div>
            <button className="button is-small is-icon" onClick={() => {setAddModalOpen(true)}}>
              <AddIcon/>
            </button>
          </div>
          <div>
            <Link to={`/todos`}>
              <p>
                Todolist
                {/*<span>(3 urgent items)</span>*/}
              </p>
            </Link>
          </div>
        </div>
        {/*<div className="TodoSummary-list">          /!*display 5 max*!/*/}

        {/*  <ul>*/}
        {/*    <li>todo items urgent</li>*/}
        {/*    <li>todo items urgent</li>*/}
        {/*    <li>todo items urgent</li>*/}
        {/*    <li>todo items urgent</li>*/}
        {/*    <li>todo items urgent</li>*/}
        {/*  </ul>*/}

        {/*</div>*/}

        <TodoAddModal addModalOpen={addModalOpen} setAddModalOpen={setAddModalOpen} addTodoSubmit={addTodoSubmit}/>
      </div>
  )
}

export default withSnackbar(TodoSummary)
