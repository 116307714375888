import AddIcon from '@material-ui/icons/AddCircle'
import React, {useEffect, useState, useCallback} from "react";
import frLocale from "date-fns/locale/fr";
import Modal from "../../commons/Modal";
import GKSelect from "../../commons/forms/GKSelect";
import {
  DatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import {DateTime} from "luxon";
import DateFnsUtils from "@date-io/date-fns";
import { useForm } from 'react-hook-form'
import withSnackbar from "../../wrappers/withSnackbar";
import WarningIcon from '@material-ui/icons/Warning';

function TodoEditModal({todo, editModalOpen,setEditModalOpen, editTodoSubmit, isDeleting, setIsDeleting, deleteTodoSubmit, editModalLoading, snackMsg}) {

  const {control, reset} = useForm()

  const [newTodoName, setNewTodoName] = useState('')
  const [newTodoDate, setNewTodoDate] = useState(DateTime.local().toISO())
  const [newTodoCat, setNewTodoCat] = useState('classic')

  useEffect(() => {
    setNewTodoName(todo.name)
    setNewTodoDate(todo.estimatedDate?todo.estimatedDate:DateTime.local().toISO())
    setNewTodoCat(todo.category)
  }, [todo])

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 13) {
        todoSubmit()
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [editModalOpen, newTodoName, newTodoDate, newTodoCat]);

  const categories = [
    {value : 'classic', label : 'Classic'},
    {value : 'spending', label : 'Spending'},
    {value : 'urgent', label : 'Urgent'},
    {value : 'project', label : 'Project'},
    {value : 'event', label : 'Event'},
    // {value : 'loop', label : 'Loop'},
  ]

  const todoSubmit = function(){
    if(editModalOpen){
      let newTodo = todo
      newTodo.name = newTodoName
      newTodo.estimatedDate = newTodoDate
      newTodo.category = newTodoCat
      editTodoSubmit(newTodo)
    }
  }

  const updateDate = function (date) {
    let isoDate = DateTime.fromISO(date).toISO()
    if(isoDate){
      setNewTodoDate(isoDate)
    }else{
      let jsDate = DateTime.fromJSDate(date).toISO()
      setNewTodoDate(jsDate)
    }
  }

  return (
    <Modal isOpened={editModalOpen} onCloseModal={() => {setEditModalOpen(false); setIsDeleting(false)}} hasCloseBtn={true} isLoading={editModalLoading} >
      <div className="life-modal" style={{width: '340px'}}>
        <h3>Edit Todo</h3>

        <div className="GKInput">
          <div className="GKInput-container">
            <input autoFocus type="text" placeholder={'Title'} value={newTodoName} onChange={e => setNewTodoName(e.target.value)}/>
          </div>
        </div>

        <div className="life-modal-select" style={{marginBottom : '10px'}}>
          <GKSelect
            name="category"
            placeholder="Category"
            control={control}
            defaultValue={newTodoCat}
            options={categories}
            customOnChange={(value) => {setNewTodoCat(value)}}
          />
        </div>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
          <DatePicker
            className="life-datetimepicker"
            placeholder="Date"
            format="dd/MM/yyyy"
            value={newTodoDate}
            onChange={date => {updateDate(date)}}
          />
        </MuiPickersUtilsProvider>

        <button className="button is-primary" onClick={() => {todoSubmit()}}>
          <span>Confirmation</span>
        </button>

        {isDeleting ?
          <button className="button is-danger life-has-icon" onClick={() => {deleteTodoSubmit(todo);setIsDeleting(false)}} style={{marginTop : '10px'}}>
            <WarningIcon/>
            <span>Are you sure ?</span>
          </button>
          :
          <button className="button is-danger" onClick={() => {setIsDeleting(true)}} style={{marginTop : '10px'}}>
            <span>Delete Todo</span>
          </button>
        }

      </div>
    </Modal>
  )
}

export default withSnackbar(TodoEditModal)
