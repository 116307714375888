import axiosInstance from '../api/axiosInstance'

const alertsAPI = {
  getAll() {
    return axiosInstance.get('/gk/alerts')
  },
  setSeen(id) {
    return axiosInstance.get(`/gk/alerts/${id}/seen`)
  },
}

export default alertsAPI