import PropTypes from 'prop-types'
import {
  Done as CompleteIcon,
  PanoramaFishEye as CurrentIcon,
} from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'

function StepProgressBar({ steps, currentStep = 1, goToStep = () => {}, isWithToolTip = false }) {
  return (
    <div className="progressBarContainer">
      <div className="progressBar">
        <div className={"progressBg stepComplete_"+ currentStep}/>
        {currentStep < (steps.length - 1) && (
          <>
            <div className={"progressBg current stepCurrent_"+ currentStep}/>
            <div className={"progressBg incoming stepIncoming_"+ currentStep}/>
          </>
        )}

        <div className="progressStepsContainer">
          {steps.map(step => (
            <div key={step.id} className={"progressStep progressStep_" + (step.id) + (parseInt(currentStep) > parseInt(step.id)?' isComplete':(currentStep === step.id?' isCurrent':''))}>
              <div className="progressLabel">
                {step.name}
              </div>

              {isWithToolTip ? (
                <Tooltip
                  title={(
                    <div className="progressBar-tooltip">
                      <span>{step.name}</span>
                      <p>Laborum omnis et et ipsum deserunt est. Consequatur commodi sunt asperiores.</p>
                    </div>
                  )}
                >
                  <div onClick={() => {goToStep(step.id)}} className="progressIndicator">
                    {parseInt(currentStep) > parseInt(step.id) &&
                      <CompleteIcon></CompleteIcon>
                    }
                    {currentStep === step.id &&
                      <CurrentIcon></CurrentIcon>
                    }
                  </div>
                </Tooltip>
              ) : (
                <div onClick={() => {goToStep(step.id)}} className="progressIndicator">
                  {parseInt(currentStep) > parseInt(step.id) &&
                    <CompleteIcon></CompleteIcon>
                  }
                  {currentStep === step.id &&
                    <CurrentIcon></CurrentIcon>
                  }
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

StepProgressBar.propTypes = {
  steps: PropTypes.array,
  currentStep: PropTypes.string,
  goToStep: PropTypes.func,
}

export default StepProgressBar
