import React, {useState, useEffect} from 'react'
import {
  Close as CloseIcon,
  AccountCircle as AccountCircleIcon,

} from '@material-ui/icons'
import  LogoGkWhite from "./../../assets/logo-square-white.png";
import { withRouter} from 'react-router-dom';
import alertsAPI from '../../api_old/alertsAPI'


function Alerts({ alerts = [], history}) {

  const [closing, setClosing] = useState([])
  const [closed, setClosed] = useState([])

  const openTask = function(task){
    let link = ''
    history.push(link)
  }

  const closeAlert = function(id){
    alertsAPI.setSeen(id).then(resp => {
      let currentClosing = [... closing];
      let currentClosed = [... closed];
      currentClosing.push(id)
      setClosing(currentClosing)

      setTimeout(function(){
        const index = currentClosing.indexOf(id);
        if (index > -1) {
          currentClosing.splice(index, 1);
        }
        setClosing(currentClosing)
        currentClosed.push(id)
        setClosed(currentClosed)
      }, 200);
    })
  }


  return (
      <div className="Alerts">
        {alerts.map((alert, idx) => ((!closed.includes(alert.id) &&
          <div key={idx} className={"Alerts-item" + (closing.includes(alert.id)?' closing':'')} style={{backgroundColor : alert.color}}>
            <div className="Alerts-item-head">

              <span>
                {alert.type === 'all' &&
                <img src={LogoGkWhite} alt="gk logo blanc" className="Alerts-item-head-icon"/>
                }
                {alert.type === 'user' && (
                    alert.user.avatar
                        ?
                        <img src={ alert.user.avatar }  alt="Avatar profil" className="Alerts-item-head-icon"/>
                        :
                        <AccountCircleIcon  className="Alerts-item-head-icon icon-gray"/>
                )
                }
                {alert.type === 'team' &&
                <AccountCircleIcon  className="Alerts-item-head-icon"/>
                }

                {alert.title}
              </span>

              <CloseIcon onClick={() => {closeAlert(alert.id)}} className="Alerts-item-head-close"/>
            </div>
            <div>{alert.description}</div>
            {alert.task &&
              <div className="Alerts-item-task">
                <button onClick={() => {openTask(alert.task)}} className="button is-small">Traiter</button>
              </div>
            }
            {alert.project &&
              <div className="Alerts-item-customer">
                {alert.project.customer.firstName + ' ' + alert.project.customer.lastName}
              </div>
            }
          </div>
            )
        ))}


      </div>
  )
}

export default withRouter(Alerts)