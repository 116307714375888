import axiosInstance from '../api/axiosInstance'

const daysAPI = {
  getAll() {
    return axiosInstance.get('/main/day')
  },
  post(body){
    return axiosInstance.post(`/main/day`, body)
  },
  getToday() {
    return axiosInstance.get('/main/day/today')
  },
}

export default daysAPI