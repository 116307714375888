import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  ArrowDropDown as ArrowDropDownIcon,
  ExitToApp as ExitToAppIcon,
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon
} from '@material-ui/icons'
// import ProjectSearch from './ProjectSearch'
import AppearDown from './transitions/AppearDown'
import logo from '../../assets/logo-greenkub.svg'
import StepProgressBar from '../commons/StepProgressBar'

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.wrapperRef = React.createRef()

    this.state = {
      isProfileMenuActive: false,
      customerStepProgressSteps: [
        { id: 1, slug: 'validation', name: 'Validation du projet' },
        { id: 2, slug: 'plu', name: 'Analyse PLU' },
        { id: 3, slug: 'depot', name: 'Dépôt du dossier en Mairie' },
        { id: 4, slug: 'accord', name: 'Accord Mairie' },
        { id: 5, slug: 'visite', name: 'Visite technique' },
        { id: 6, slug: 'installation', name: 'Installation' },
      ]
    }

    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.onToggleProfileMenu = this.onToggleProfileMenu.bind(this)
  }

  onToggleProfileMenu(event) {
    event.stopPropagation()

    this.setState({
      isProfileMenuActive: !this.state.isProfileMenuActive
    })
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        isProfileMenuActive: false
      })
    }
  }

  render() {
    return (
      <header className={"Header" + (this.props.role === 'CUSTOMER'? ' role_customer' : '')} ref={this.wrapperRef}>

        {this.props.role !== 'CUSTOMER' && (
          <button className="Header-toggleMenuMobile icon-button" onClick={this.props.onToggleMenu}>
            <MenuIcon/>
          </button>
        )}

        <p></p>

        <div
          className="Header-profile"
          onClick={this.onToggleProfileMenu}
        >
          { this.props.user.avatar
            ? <img className="Header-avatar" src={ this.props.user.avatar } alt="Avatar profil"/>
            : <AccountCircleIcon className="Header-avatar" alt="Avatar profil"/>
          }

          <div className="Header-profile-infos">
            <span className="Header-profile-name">{ this.props.user.name }</span>
            <span className="Header-profile-email">{ this.props.user.email }</span>
          </div>

          <button
            className={`Header-toggleProfileMenu icon-button${this.state.isProfileMenuActive === true ? ' active' : ''}`}
          >
            <ArrowDropDownIcon/>
          </button>

          <div className="Header-profile-menu">
            <AppearDown
              isActive={this.state.isProfileMenuActive}
              duration=".4s"
            >
              <ul>
                {/*<li>*/}
                  {/*<Link to="/profil">*/}
                    {/*<PersonIcon/>*/}
                    {/*<span>Mon profil</span>*/}
                  {/*</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                  {/*<Link to="/parametres">*/}
                    {/*<SettingsIcon/>*/}
                    {/*<span>Paramètres</span>*/}
                  {/*</Link>*/}
                {/*</li>*/}
                <li>
                  <button onClick={this.props.logout}>
                    <ExitToAppIcon/>
                    <span>Déconnexion</span>
                  </button>
                </li>
              </ul>
            </AppearDown>
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func,
  onToggleMenu: PropTypes.func,
  customerProjectCurrentStep: PropTypes.number,
}

export default Header

