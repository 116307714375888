import React from 'react';
import { Group } from '@visx/group';
import genBins, { Bin, Bins } from '@visx/mock-data/lib/generators/genBins';
import { scaleLinear } from '@visx/scale';
import { HeatmapCircle, HeatmapRect } from '@visx/heatmap';
import { getSeededRandom } from '@visx/mock-data';

const hot1 = '#c2070e';
const hot2 = '#ffeb3b';
const cool1 = '#262626';
const cool2 = '#8bbf45';
export const background = '#fff';

const seededRandom = getSeededRandom(0.41);

const binData = genBins(
  /* length = */ 16,
  /* height = */ 7,
  /* binFunc */ idx => 5 * idx,
  /* countFunc */ (i, number) => 5 * seededRandom(),
);

function max<Datum>(data: Datum[], value: (d: Datum) => number): number {
  return Math.max(...data.map(value));
}

function min<Datum>(data: Datum[], value: (d: Datum) => number): number {
  return Math.min(...data.map(value));
}

// accessors
const bins = (d: Bins) => d.bins;
const count = (d: Bin) => d.count;

const colorMax = max(binData, d => max(bins(d), count));
const bucketSizeMax = max(binData, d => bins(d).length);

// scales
const xScale = scaleLinear({
  domain: [0, binData.length],
});
const yScale = scaleLinear({
  domain: [0, bucketSizeMax],
});
const circleColorScale = scaleLinear({
  range: [cool1, cool2],
  domain: [0, colorMax],
});
const rectColorScale = scaleLinear({
  range: [hot1, hot2],
  domain: [0, colorMax],
});

export type HeatmapProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  events?: boolean;
};

const defaultMargin = { top: 30, left: 10, right: 10, bottom: 0 };

const StatsHeatmap = ({width, height, events = false, margin = defaultMargin, type, fullData}: HeatmapProps) => {
  const xMax = width > margin.left + margin.right ? width - margin.left - margin.right : width;
  const yMax = height - margin.bottom - margin.top;

  const binWidth = xMax / binData.length;
  const binHeight = yMax / bucketSizeMax;
  const radius = min([binWidth, binHeight], d => d) / 2;

  xScale.range([0, xMax]);
  yScale.range([0, yMax]);

  return width < 10 ? null : (
    <svg width={width} height={height}>
      <rect x={0} y={0} width={width} height={height} rx={14} fill={'transparent'} />
      {type === 'resting' ?
        <Group top={margin.top} left={margin.left}>
          <HeatmapCircle
            data={fullData}
            xScale={d => xScale(d) ?? 0}
            yScale={d => yScale(d) ?? 0}
            colorScale={circleColorScale}
            radius={radius}
            gap={2}
          >
            {heatmap =>
              heatmap.map(heatmapBins =>
                heatmapBins.map(bin => (
                  <circle
                    key={`heatmap-circle-${bin.row}-${bin.column}`}
                    className="visx-heatmap-circle"
                    cx={bin.cx}
                    cy={bin.cy}
                    r={bin.r}
                    fill={bin.count > 0.5 ? bin.color : '#F2F2F2'}
                    onClick={() => {
                      if (!events) return;
                      const { row, column } = bin;
                      alert(JSON.stringify({ row, column, bin: bin.bin }));
                    }}
                  />
                )),
              )
            }
          </HeatmapCircle>
        </Group>
        :
        <Group top={margin.top} left={margin.left}>
          <HeatmapRect
            data={fullData}
            xScale={d => xScale(d) ?? 0}
            yScale={d => yScale(d) ?? 0}
            colorScale={rectColorScale}
            binWidth={binWidth}
            binHeight={binWidth}
            gap={2}
          >
            {heatmap =>
              heatmap.map(heatmapBins =>
                heatmapBins.map(bin => (
                  <rect
                    key={`heatmap-rect-${bin.row}-${bin.column}`}
                    className="visx-heatmap-rect"
                    width={bin.width}
                    height={bin.height}
                    x={bin.x}
                    // toto={() => {console.log(bin)}}
                    y={bin.y}
                    fill={bin.count > 0.5 ? bin.color : '#F2F2F2'}
                    onClick={() => {
                      if (!events) return;
                      const { row, column } = bin;
                      alert(JSON.stringify({ row, column, bin: bin.bin }));
                    }}
                  />
                )),
              )
            }
          </HeatmapRect>
        </Group>
      }
    </svg>
  );
};

export default StatsHeatmap;
