import StarIcon from '@material-ui/icons/Star'
import BatteryEmptyIcon from '@material-ui/icons/BatteryFull';
import BatteryFullIcon from '@material-ui/icons/BatteryChargingFull';

import SmokingRoomsIcon from '@material-ui/icons/SmokingRooms';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import EcoIcon from '@material-ui/icons/Eco';
import WavesIcon from '@material-ui/icons/Waves';

import React, {useEffect, useState} from "react";
import Modal from "../../commons/Modal";
import { useForm } from 'react-hook-form'
import withSnackbar from "../../wrappers/withSnackbar";

function TodayRateModal({rateModalOpen,setRateModalOpen,rateModalLoading, rateModalSubmit,rateTodayExisting,  snackMsg}) {

  const {control, reset} = useForm()

  const [todayRatingScore, setTodayRatingScore] = useState(1)
  const [todayRestingScore, setTodayRestingScore] = useState(1)

  const [todayIsSmoke, setTodayIsSmoke] = useState(false)
  const [todayIsDrink, setTodayIsDrink] = useState(false)
  const [todayIsTHC, setTodayIsTHC] = useState(false)
  const [todayIsProd, setTodayIsProd] = useState(false)

  useEffect(() => {
    if(rateTodayExisting){
      setTodayRatingScore(rateTodayExisting.rating)
      setTodayRestingScore(rateTodayExisting.tiring)
      setTodayIsSmoke(rateTodayExisting.isSmokeDay)
      setTodayIsDrink(rateTodayExisting.isDrinkDay)
      setTodayIsTHC(rateTodayExisting.isThcDay)
      setTodayIsProd(rateTodayExisting.isProdDay)
    }
  }, [rateTodayExisting])

  return (
    <Modal isOpened={rateModalOpen} isLoading={rateModalLoading} onCloseModal={() => setRateModalOpen(false)} hasCloseBtn={true}>
      <div className="TodayRateModal life-modal">
        <h3>How was today ?</h3>

        <div className="TodayRateModal-ratings">

          <p>Overall score</p>
          <StarIcon className="TodayRateModal-star-full" onClick={() => {setTodayRatingScore(1)}}/>
          {todayRatingScore >=2 ?
            <StarIcon className="TodayRateModal-star-full" onClick={() => {setTodayRatingScore(2)}}/>
            :
            <StarIcon className="TodayRateModal-empty" onClick={() => {setTodayRatingScore(2)}}/>
          }
          {todayRatingScore >=3 ?
            <StarIcon className="TodayRateModal-star-full" onClick={() => {setTodayRatingScore(3)}}/>
            :
            <StarIcon className="TodayRateModal-empty" onClick={() => {setTodayRatingScore(3)}}/>
          }
          {todayRatingScore >=4 ?
            <StarIcon className="TodayRateModal-star-full" onClick={() => {setTodayRatingScore(4)}}/>
            :
            <StarIcon className="TodayRateModal-empty" onClick={() => {setTodayRatingScore(4)}}/>
          }
          {todayRatingScore ===5 ?
            <StarIcon className="TodayRateModal-star-full" onClick={() => {setTodayRatingScore(5)}}/>
            :
            <StarIcon className="TodayRateModal-empty" onClick={() => {setTodayRatingScore(5)}}/>
          }

          <p>Resting score</p>

          <BatteryFullIcon className="TodayRateModal-rest-full" onClick={() => {setTodayRestingScore(1)}}/>
          {todayRestingScore >=2 ?
            <BatteryFullIcon className="TodayRateModal-rest-full" onClick={() => {setTodayRestingScore(2)}}/>
            :
            <BatteryEmptyIcon className="TodayRateModal-empty" onClick={() => {setTodayRestingScore(2)}}/>
          }
          {todayRestingScore >=3 ?
            <BatteryFullIcon className="TodayRateModal-rest-full" onClick={() => {setTodayRestingScore(3)}}/>
            :
            <BatteryEmptyIcon className="TodayRateModal-empty" onClick={() => {setTodayRestingScore(3)}}/>
          }
          {todayRestingScore >=4 ?
            <BatteryFullIcon className="TodayRateModal-rest-full" onClick={() => {setTodayRestingScore(4)}}/>
            :
            <BatteryEmptyIcon className="TodayRateModal-empty" onClick={() => {setTodayRestingScore(4)}}/>
          }
          {todayRestingScore ===5 ?
            <BatteryFullIcon className="TodayRateModal-rest-full" onClick={() => {setTodayRestingScore(5)}}/>
            :
            <BatteryEmptyIcon className="TodayRateModal-empty" onClick={() => {setTodayRestingScore(5)}}/>
          }


        </div>

        <div className="TodayRateModal-addict-pauses">

          <div className="TodayRateModal-addict-pauses-item">
            <p>Smoked tabacco</p>
            <button className={"button " + (todayIsSmoke ? 'is-danger' : 'is-light')} onClick={() => {setTodayIsSmoke(!todayIsSmoke)}}>
              <SmokingRoomsIcon/>
            </button>
          </div>

          <div className="TodayRateModal-addict-pauses-item">
            <p>Drank alcohol</p>
            <button className={"button " + (todayIsDrink ? 'is-danger' : 'is-light')} onClick={() => {setTodayIsDrink(!todayIsDrink)}}>
              <LocalBarIcon/>
            </button>
          </div>

          <div className="TodayRateModal-addict-pauses-item">
            <p>Smoked weed</p>
            <button className={"button " + (todayIsTHC ? 'is-danger' : 'is-light')} onClick={() => {setTodayIsTHC(!todayIsTHC)}}>
              <EcoIcon/>
            </button>
          </div>

          <div className="TodayRateModal-addict-pauses-item">
            <p>Took prods</p>
            <button className={"button " + (todayIsProd ? 'is-danger' : 'is-light')} onClick={() => {setTodayIsProd(!todayIsProd)}}>
              <WavesIcon/>
            </button>
          </div>

        </div>

        <button className="button is-primary" onClick={() => {rateModalSubmit(todayIsDrink, todayIsProd, todayIsSmoke, todayIsTHC, todayRatingScore, todayRestingScore)}}>
          <span>Confirmation</span>
        </button>
      </div>
    </Modal>
  )
}

export default withSnackbar(TodayRateModal)
