import React from 'react'
import { connect } from 'react-redux'
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  ErrorOutline as ErrorOutlineIcon
} from '@material-ui/icons/';
import { CSSTransition } from "react-transition-group"

import { removeSnackMessage } from '../../redux/snackbar/snackbarActions'

class Snackbar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      msgsTimeouts: []
    }
  }

  static getDerivedStateFromProps(props, oldState) {
    const msgsIds = props.messages.map(msg => msg.id)
    const newMsgsTimeouts = oldState.msgsTimeouts.filter(msgId => msgsIds.includes(msgId))

    for (let msg of props.messages) {
      if (!oldState.msgsTimeouts.includes(msg.id)) {
        newMsgsTimeouts.push(msg.id)
        setTimeout(() => {
          props.removeSnackMessage(msg.id)
        }, 4000)
      }
    }

    return {
      msgsTimeouts: newMsgsTimeouts
    }
  }

  render() {
    return (
      <ul className="Snackbar">
        {this.props.messages.map(msg => (
          <CSSTransition
            classNames="Snackbar-item"
            key={msg.id}
            in={true}
            appear={true}
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
          >
            <li className={`Snackbar-item is-${msg.severity}`}>
              {msg.severity === 'success' && (<CheckCircleOutlineIcon/>)}
              {msg.severity === 'error' && (<ErrorOutlineIcon/>)}
              <span>{msg.content}</span>
            </li>
          </CSSTransition>
        ))}
      </ul>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    messages: state.snackbarReducer.messages
  }
}

const mapDispatchToProps = dispatch => ({
  removeSnackMessage: (msgId) => dispatch(removeSnackMessage(msgId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar)