import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Close as CloseIcon } from '@material-ui/icons'

function Modal({ className, isOpened, onCloseModal, children, hasCloseBtn, isLoading }) {
  return !isOpened ? null : ReactDOM.createPortal(
    <div className="Modal-overlay" onClick={onCloseModal}>
      <div className={`Modal ${className ? className : ''}`} onClick={e => e.stopPropagation()}>
        {hasCloseBtn && !isLoading && (
          <button className="Modal-close icon-button" onClick={onCloseModal}>
            <CloseIcon/>
          </button>
        )}
        {isLoading ?
          <div className='Modal-loader'>
            <div className="lds-hourglass"></div>
            <p>Loading...</p>
          </div>
          :
          <div className="Modal-content">
            {children}
          </div>
        }


      </div>
    </div>,
    document.getElementById('modal-root')
  )
}

Document.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default Modal
