import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import classnames from 'classnames'
import Select from 'react-select'

import AppearDown from '../transitions/AppearDown'

function GKSelect({
  placeholder = '',
  name,
  options,
  rules,
  control,
  errorMsg,
  defaultValue,
  customOnChange = false,
  isMulti = false
}) {
  const [isFocus, setFocus] = useState(false)

  return (
    <div className={classnames('GKInput', {'is-focused': isFocus})}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ onChange, value }) => {

          value = defaultValue

          return (
            <Select
              isMulti={isMulti}
              name={name}
              value={options.find((opt) => opt.value === value)}
              placeholder={placeholder}
              options={options}
              onChange={(option) => {customOnChange? customOnChange(option.value) : onChange(option.value)}}
              classNamePrefix="GKInput-select"
            />
          )
        }}
      />

      <AppearDown isActive={errorMsg !== null && errorMsg !== undefined} duration=".3s">
        <span className="form-msg is-error">{errorMsg}</span>
      </AppearDown>
    </div>
  )
}

export default GKSelect
