import {
  Speed,
  ArrowBackIos,
  Close,
} from '@material-ui/icons';
import { Link } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import {getIcon} from "../../helpers";

class Menu extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      appName: process.env.REACT_APP_NAME,
    }
  }

  render() {
    return (
      <div className="Menu-wrapper">
        <nav className="Menu">
          <div className="Menu-head">
            <p></p>
            <Link onClick={this.props.onToggleMenu} to="/">
              <p className="Menu-title">✨ {this.state.appName} ✨</p>
            </Link>
            <button className="Menu-toggleCloseMobile icon-button" onClick={this.props.onToggleMenu}>
              <Close/>
            </button>
          </div>
          <ul>
            <li className="item_todo">
              <Link onClick={this.props.onToggleMenu} to="/">
                {getIcon('dashboard')}
                <span>Dashboard</span>
              </Link>
            </li>
            <li className="item_todo">
              <Link onClick={this.props.onToggleMenu} to="/todos">
                {getIcon('todo')}
                <span>Todolist</span>
              </Link>
            </li>
            <li className="item_stat">
              <Link onClick={this.props.onToggleMenu} to="/stats">
                {getIcon('stat')}
                <span>Stats</span>
              </Link>
            </li>
            <li className="item_spend">
              <Link onClick={this.props.onToggleMenu} to="/spendings">
                {getIcon('spend')}
                <span>Spendings</span>
              </Link>
            </li>
            <li className="item_project">
              <Link onClick={this.props.onToggleMenu} to="/projects">
                {getIcon('project')}
                <span>Projects</span>
              </Link>
            </li>
            <li className="item_area">
              <Link onClick={this.props.onToggleMenu} to="/areas">
                {getIcon('area')}
                <span>Areas</span>
              </Link>
            </li>
          </ul>
          <div id="menu-portal"></div>
        </nav>
      </div>
    )
  }
}

Menu.propTypes = {
  menuToggle: PropTypes.bool,
  onToggleMenu: PropTypes.func
}

export default Menu
