import AddIcon from '@material-ui/icons/AddCircle'
import React, {useCallback, useEffect, useState} from "react";
import frLocale from "date-fns/locale/fr";
import Modal from "../../commons/Modal";
import GKSelect from "../../commons/forms/GKSelect";
import {
  DatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import {DateTime} from "luxon";
import DateFnsUtils from "@date-io/date-fns";
import { useForm } from 'react-hook-form'
import withSnackbar from "../../wrappers/withSnackbar";

function TodoAddModal({addModalOpen,setAddModalOpen, addTodoSubmit,addModalLoading, currentMonth, snackMsg}) {

  const {control, reset} = useForm()

  const [newTodoName, setNewTodoName] = useState('')
  const [newTodoDate, setNewTodoDate] = useState(DateTime.local().toISO())
  const [newTodoCat, setNewTodoCat] = useState('classic')

  const [monthDate, setMonthDate] = useState(DateTime.local().toISO())

  const categories = [
    {value : 'classic', label : 'Classic'},
    {value : 'spending', label : 'Spending'},
    {value : 'urgent', label : 'Urgent'},
    {value : 'project', label : 'Project'},
    {value : 'event', label : 'Event'},
    // {value : 'loop', label : 'Loop'},
  ]

  useEffect(() => {
    let nowMonth = DateTime.local().setLocale("en").monthLong
    if(nowMonth !== currentMonth && currentMonth){
      setNewTodoDate(DateTime.fromFormat(currentMonth, 'LLLL' ).toISO())
      setMonthDate(DateTime.fromFormat(currentMonth, 'LLLL' ))
    }else{
      setNewTodoDate(DateTime.local().toISO())
      setMonthDate(DateTime.local())
    }
  }, [addModalOpen, currentMonth])

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 13) {
        todoSubmit()
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [addModalOpen, newTodoName, newTodoDate, newTodoCat]);

  const todoSubmit = function(){
    if(addModalOpen){
      addTodoSubmit(newTodoName, newTodoDate, newTodoCat)
      setNewTodoName('')
      setNewTodoDate(DateTime.local().toISO())
      setNewTodoCat('classic')
    }
  }

  const addDaysToNewDate = function (days) {
    setNewTodoDate(monthDate.plus({ days: days }))
  }

  return (
    <Modal isOpened={addModalOpen} isLoading={addModalLoading} onCloseModal={() => setAddModalOpen(false)} hasCloseBtn={true}>
      <div className="life-modal">
        <h3>Add Todo</h3>

        <div className="GKInput">
          <div className="GKInput-container">
            <input autoFocus type="text" placeholder={'Title'} value={newTodoName} onChange={e => setNewTodoName(e.target.value)}/>
          </div>
        </div>

        <div className="life-modal-select">
          <GKSelect
            name="category"
            placeholder="Category"
            control={control}
            defaultValue={newTodoCat}
            options={categories}
            customOnChange={(value) => {setNewTodoCat(value)}}
          />
        </div>

        <div className="life-modal-choices">
          <button className="button is-small" onClick={() => {addDaysToNewDate(0)}}>
            <span>NOW</span>
          </button>
          <button className="button is-small" onClick={() => {addDaysToNewDate(7)}}>
            <span>7 DAYS</span>
          </button>
          <button className="button is-small" onClick={() => {addDaysToNewDate(15)}}>
            <span>15 DAYS</span>
          </button>
          <button className="button is-small" onClick={() => {addDaysToNewDate(30)}}>
            <span>1 MONTH</span>
          </button>
        </div>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
          <DatePicker
            className="life-datetimepicker"
            placeholder="Date"
            format="dd/MM/yyyy"
            value={newTodoDate}
            onChange={date => {setNewTodoDate(date)}}
          />
        </MuiPickersUtilsProvider>


        {/*link to project*/}
        {/*link to area*/}
        {/*add spending*/}
        {/*add alert*/}

        <button className="button is-primary" onClick={() => {todoSubmit()}}>
          <span>Confirmation</span>
        </button>
      </div>
    </Modal>
  )
}

export default withSnackbar(TodoAddModal)
