import React, {createRef,useRef, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import StarIcon from '@material-ui/icons/Star'
import todosAPI from "../../../api/todosAPI"

import TodoItem from '../../commons/todo/TodoItem'
import ProgressBar from '../../commons/ProgressBar'
import {DateTime} from "luxon";
import {getErrorMsgFromSymfonyResponse, getIcon} from "../../../helpers";
import {Link} from "react-router-dom";
import withSnackbar from "../../wrappers/withSnackbar";
import TodoAddModal from "../../commons/todo/TodoAddModal";
import TodoEditModal from "../../commons/todo/TodoEditModal";

import AddIcon from "@material-ui/icons/AddCircle";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

function Todos({snackMsg}) {

  const [isProgressBarActive, setIsProgressBarActive] = useState(true)

  // ADD MODAL
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [addModalLoading, setAddModalLoading] = useState(false)

  // EDIT MODAL
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editModalLoading, setEditModalLoading] = useState(false)

  // EDIT TODO
  const [editTodo, setEditTodo] = useState(false)
  const [editTodoOldMonth, setEditTodoOldMonth] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  // DELETE TODO
  const [isDeletingInit, setIsDeletingInit] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  // DATEPICKER
  const [currentYear, setCurrentYear] = useState(DateTime.local().year)
  const [currentMonth, setCurrentMonth] = useState(DateTime.local().setLocale("en").monthLong)
  const allMonth = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const [thisYearTasks, setThisYearTasks] = useState({
    'January' : {'todo' : [], 'done' : []},
    'February' : {'todo' : [], 'done' : []},
    'March' : {'todo' : [], 'done' : []},
    'April' : {'todo' : [], 'done' : []},
    'May' : {'todo' : [], 'done' : []},
    'June' : {'todo' : [], 'done' : []},
    'July' : {'todo' : [], 'done' : []},
    'August' : {'todo' : [], 'done' : []},
    'September' : {'todo' : [], 'done' : []},
    'October' : {'todo' : [], 'done' : []},
    'November' : {'todo' : [], 'done' : []},
    'December' : {'todo' : [], 'done' : []}
  })
  const currentMonthRef = useRef(null)

  useEffect(() => {
    fetchTodos()
  }, [])

  const fetchTodos = function(resetYear = false){
    todosAPI.getAll(resetYear?resetYear:currentYear).then((resp) => {
      let allYearlyTasks = thisYearTasks
      if(resetYear){
        allYearlyTasks = {
          'January' : {'todo' : [], 'done' : []},
          'February' : {'todo' : [], 'done' : []},
          'March' : {'todo' : [], 'done' : []},
          'April' : {'todo' : [], 'done' : []},
          'May' : {'todo' : [], 'done' : []},
          'June' : {'todo' : [], 'done' : []},
          'July' : {'todo' : [], 'done' : []},
          'August' : {'todo' : [], 'done' : []},
          'September' : {'todo' : [], 'done' : []},
          'October' : {'todo' : [], 'done' : []},
          'November' : {'todo' : [], 'done' : []},
          'December' : {'todo' : [], 'done' : []}
        }
      }
      resp.data.map((task, idx) => {
        let month = DateTime.fromISO(task.estimatedDate).setLocale("en").monthLong
        if(task.doneAt){
          allYearlyTasks[month].done.push(task)
        }else{
          allYearlyTasks[month].todo.push(task)
        }
      })
      setThisYearTasks(allYearlyTasks)
      setIsProgressBarActive(false)
      scrollToCurrentMonth()
    }).catch(error => {
      snackMsg('error', getErrorMsgFromSymfonyResponse(error.response))
    })
  }

  // const sleep = function(time) {
  //   return new Promise((resolve) => setTimeout(resolve, time));
  // }

  const addTodoSubmit = function (newTodoName, newTodoDate, newTodoCat) {
    setAddModalLoading(true)
    todosAPI.post({
      name : newTodoName,
      estimated_date : newTodoDate,
      category : newTodoCat,
    }).then(resp => {
      setAddModalOpen(false)
      setAddModalLoading(false)
      let year = DateTime.fromISO(resp.data.estimatedDate).year
      if(year === currentYear){
        let month = DateTime.fromISO(resp.data.estimatedDate).setLocale("en").monthLong
        let allYearlyTasks = {... thisYearTasks};
        let idx = findIndexTodo(resp.data, allYearlyTasks[month].todo)
        allYearlyTasks[month].todo.splice(idx, 0, resp.data);
        setThisYearTasks(allYearlyTasks)
      }
      scrollToCurrentMonth()
      snackMsg('success', "Todo saved")
    }).catch(error => {
      snackMsg('error', getErrorMsgFromSymfonyResponse(error.response))
    })
  }

  const scrollToCurrentMonth = function(){
    if(currentMonthRef.current){
      currentMonthRef.current.scrollIntoView({behavior: "smooth"})
    }
  }

  const findIndexTodo = function(todo, array){
    let day = DateTime.fromISO(todo.estimatedDate).toFormat('d')
    let index = null
    array.map((task, idx) => {
      let currentTaskDay = DateTime.fromISO(task.estimatedDate).toFormat('d')
      if(currentTaskDay > day && index === null){
        index = idx
      }
    })
    return index?index:array.length+1
  }

  const findIndexTodoById = function(id, array){
    let index = null
    array.map((task, idx) => {
      if(task.id === id){
        index = idx
      }
    })
    return index?index:array.length+1
  }

  const replaceTodoById = function(todo){

    let newMonth = DateTime.fromISO(todo.estimatedDate).setLocale("en").monthLong
    let newYear = DateTime.fromISO(todo.estimatedDate).year
    let id = todo.id
    let allYearlyTasks = {... thisYearTasks};

    if(editTodoOldMonth === newMonth){ // if month does not change => replace in month array

      let array = allYearlyTasks[newMonth].todo
      if(todo.doneAt){
        array = allYearlyTasks[newMonth].done
      }
      let index = null
      array.map((task, idx) => {
        if(task.id === id && index === null){
          index = idx
        }
      })
      if(index !== null){
        if(todo.doneAt){
          allYearlyTasks[newMonth].done[index] = todo
        }else{
          allYearlyTasks[newMonth].todo[index] = todo
        }
      }
    }else{
      //   delete old element in old month
      if(todo.doneAt){
        let idx = findIndexTodo(todo, allYearlyTasks[editTodoOldMonth].done)
        allYearlyTasks[editTodoOldMonth].done.splice(idx, 1);
      }else{
        let idx = findIndexTodo(todo, allYearlyTasks[editTodoOldMonth].todo)
        allYearlyTasks[editTodoOldMonth].todo.splice(idx, 1);
      }
      if(newYear === currentYear){// and if new is in current year add in month
        let idx = findIndexTodo(todo, allYearlyTasks[newMonth].todo)
        allYearlyTasks[newMonth].todo.splice(idx, 0, todo);
      }
    }
    setThisYearTasks(allYearlyTasks)
  }

  const editTodoInit = function (todo) {
    setEditTodo(todo)
    setEditModalOpen(true)
    setEditTodoOldMonth(DateTime.fromISO(todo.estimatedDate).setLocale("en").monthLong)
  }

  const editTodoSubmit = function (todo) {
    if(!isUpdating){
      setEditModalLoading(true)
      setIsUpdating(true);
      todosAPI.patch(
          todo.id,
    {
            name : todo.name,
            estimated_date : todo.estimatedDate,
            category : todo.category
          }
        ).then(resp => {
        setEditModalLoading(false)
        setIsUpdating(false);
        replaceTodoById(todo)
        setEditModalOpen(false)
        snackMsg('success', "Todo saved")
      }).catch(error => {
        snackMsg('error', getErrorMsgFromSymfonyResponse(error.response))
      })
    }
  }

  const deleteTodoSubmit = function (todo) {
    if(!isDeleting){
      setEditModalLoading(true)
      setIsDeleting(true)
      todosAPI.delete(todo.id).then(resp => {
        setEditModalLoading(false)
        setIsDeletingInit(false);
        setIsDeleting(false);
        let allYearlyTasks = {... thisYearTasks};
        if(todo.doneAt){
          let idx = findIndexTodoById(todo.id, allYearlyTasks[editTodoOldMonth].done)
          allYearlyTasks[editTodoOldMonth].done.splice(idx, 1);
        }else{
          let idx = findIndexTodoById(todo.id, allYearlyTasks[editTodoOldMonth].todo)
          allYearlyTasks[editTodoOldMonth].todo.splice(idx, 1);
        }
        setThisYearTasks(allYearlyTasks)

        setEditModalOpen(false)
        snackMsg('success', "Todo deleted")
      }).catch(error => {
        snackMsg('error', getErrorMsgFromSymfonyResponse(error.response))
      })
    }
  }

  const openAddModal = function(month){
    setCurrentMonth(month)
    setAddModalOpen(true)
  }

  return (
    <div className="Todos">
      <ProgressBar isActive={isProgressBarActive}/>

      <div className="Todos-head card">
        <h1 className="life-h1" onClick={scrollToCurrentMonth}>Todolist - {currentYear}</h1>
      </div>

      {/*TODO URGENT COMPONENT*/}
      {/*<div className="Todos-urgent card card-danger">*/}
      {/*  urgent & late card*/}
      {/*accordion ?*/}
      {/*</div>*/}

      <div className="Todos-main card" style={{marginTop : '15px'}}>
        <button className="button is-small Todos-year-navigate" onClick={() => {setCurrentYear(currentYear - 1); fetchTodos(currentYear - 1)}}><NavigateBeforeIcon/> {currentYear - 1}</button>

        {allMonth.map((month, idx) => (
          <div
            key={idx}
            className={"Todos-month" + (currentYear === DateTime.local().year && currentMonth === month ? ' currentMonth':'' )}
            ref={currentYear === DateTime.local().year && currentMonth === month ? currentMonthRef:null}
          >
            <div className={"Todos-month-head"}>
              <button className="button is-small is-icon" onClick={() => {openAddModal(month)}}>
                <AddIcon/>
              </button>
              <h2>{month} {currentYear}</h2>
            </div>

            <div className={"Todos-month-todolist"}>
              {thisYearTasks[month].done.length === 0 && thisYearTasks[month].todo.length === 0 ?
                <div className="Todos-month-empty">
                  -
                </div>
                :
                <>
                  {thisYearTasks[month].done.map((todo, idxt) => (
                    <TodoItem key={idxt} todo={todo} editTodoInit={() => {editTodoInit(todo)}} />
                  ))}
                  {thisYearTasks[month].todo.map((todo, idxt) => (
                    <TodoItem key={idxt} todo={todo} editTodoInit={() => {editTodoInit(todo)}} />
                  ))}
                </>
              }
            </div>
          </div>
        ))}

        <button className="button is-small Todos-year-navigate" style={{marginTop : '15px'}} onClick={() => {setCurrentYear(currentYear + 1); fetchTodos(currentYear + 1)}}>{currentYear + 1} <NavigateNextIcon/></button>
      </div>
      <TodoAddModal
        addModalOpen={addModalOpen}
        setAddModalOpen={setAddModalOpen}
        addModalLoading={addModalLoading}
        addTodoSubmit={addTodoSubmit}
        currentMonth={currentMonth}
      />
      <TodoEditModal
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        todo={editTodo}
        editTodoSubmit={editTodoSubmit}
        isDeleting={isDeletingInit}
        setIsDeleting={setIsDeletingInit}
        deleteTodoSubmit={deleteTodoSubmit}
        editModalLoading={editModalLoading}
      />
    </div>
  )
}

Todos.propTypes = {
  user: PropTypes.object
}

export default withSnackbar(Todos)

