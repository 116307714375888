import { useState, useEffect } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import LogoGkWhite from "./../../../assets/logo-square-white.png";
import {
  Folder,
  ShoppingCart,
} from '@material-ui/icons';
import { Link } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";

function CustomerMenu() {
  const [currentPageName, setCurrentPageName] = useState()

  const location = useLocation()

  useEffect(() => {
    const matchParams = {
      path: location.pathname,
      exact: true
    }

    if (matchPath('/documents', matchParams) !== null) {
      setCurrentPageName('Documents')
    } else if (matchPath('/mon-panier', matchParams) !== null) {
      setCurrentPageName('Mon panier')
    } else {
      setCurrentPageName('Mon projet')
    }
  }, [location.pathname])

  return (
    <nav className="CustomerMenu">
      <ul>
        <li>
          <Link to="/documents">
            <Folder/>
            <span>Documents</span>
          </Link>
        </li>
        <li>
          <Link to="/">
            <img src={LogoGkWhite} alt=""/>
            <span>Mon projet</span>
          </Link>
        </li>
        <li>
          <Link to="/mon-panier">
            <ShoppingCart/>
            <span>Mon panier</span>
          </Link>
        </li>
      </ul>
      <span className="CustomerMenu-pageName">{currentPageName}</span>
    </nav>
  )
}

CustomerMenu.propTypes = {
  menuToggle: PropTypes.bool,
  onToggleMenu: PropTypes.func
}

export default CustomerMenu
