import React, {useState} from "react";

import todosAPI from "../../../api/todosAPI"
import {DateTime} from "luxon";
import withSnackbar from "../../wrappers/withSnackbar";

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EventNoteIcon from '@material-ui/icons/EventNote';
import EuroIcon from '@material-ui/icons/Euro';
import ScheduleIcon from '@material-ui/icons/Schedule';
import BuildIcon from '@material-ui/icons/Build';
import LoopIcon from '@material-ui/icons/Loop';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import {getErrorMsgFromSymfonyResponse} from "../../../helpers";

function TodoItem({todo, editTodoInit, snackMsg}) {

  const [done, setDone] = useState(Boolean(todo.doneAt))
  const [isUpdating, setIsUpdating] = useState(false)

  const displayCategoryIcon = function(category){
    switch (category) {
      case 'classic' :
        return (<EventNoteIcon/>)
      case 'spending' :
        return (<EuroIcon/>)
      case 'urgent' :
        return (<ScheduleIcon/>)
      case 'project' :
        return (<BuildIcon/>)
      case 'event' :
        return (<EventAvailableIcon/>)
      case 'loop' :
        return (<LoopIcon/>)
    }
    return ''
  }

  const toggleDone = function () {
    if(!isUpdating){
      setIsUpdating(true);
      todosAPI.patch(todo.id, {done_at : done?null:DateTime.local()}).then(resp => {
        setDone(!done)
        setIsUpdating(false);
        snackMsg('success', "Todo saved")
      }).catch(error => {
        snackMsg('error', getErrorMsgFromSymfonyResponse(error.response))
      })
    }
  }

  return (
      <div className={"TodoItem" + (done?' TodoItem-done':'')}>

        <div className={"TodoItem-cat TodoItem-cat-" + todo.category}>
          {displayCategoryIcon(todo.category)} {/*/ switch function*/}
        </div>
        <div className="TodoItem-content">
          <div className="TodoItem-content-name">
            {todo.name}
          </div>
          <div className="TodoItem-content-date">{/*TODO on click open datepicker modal only and change date*/}
            <CalendarTodayIcon/>
            {done ?
              <span>{todo.doneAt ? DateTime.fromISO(todo.doneAt).toFormat('d') : DateTime.local().toFormat('d')}</span>
              :
              <span>{DateTime.fromISO(todo.estimatedDate).toFormat('d')}</span>
            }
          </div>
        </div>
        <div className="TodoItem-actions">
          <button className={"button is-small"}>
            {done ?
              <ClearIcon onClick={toggleDone}/>
              :
              <CheckIcon onClick={toggleDone}/>
            }
          </button>
          <button className="button is-small is-light">
            <MoreVertIcon onClick={editTodoInit}/>
          </button>
        </div>
      </div>
  )
}

export default withSnackbar(TodoItem)
