import frLocale from "date-fns/locale/fr";
import {DateTime} from "luxon";
import DateFnsUtils from "@date-io/date-fns";
import {useEffect, useState} from "react";
import daysAPI from "../../../api/daysAPI";
import {getErrorMsgFromSymfonyResponse} from "../../../helpers";

function StatsMonthsDisplay() {

  const [dt, setDt] = useState(DateTime.local())
  const [nowMonth, setNowMonth] = useState('')
  const [prevMonth, setPrevMonth] = useState('')
  const [prev2Month, setPrev2Month] = useState('')
  const [prev3Month, setPrev3Month] = useState('')
  const [daysInClass, setDaysInClass] = useState('startMonth')

  useEffect(() => {
    let startMonth = dt.startOf('month')

    setNowMonth(dt.toFormat("LLL"))
    setPrevMonth(dt.minus({month : 1}).toFormat("LLL"))
    setPrev2Month(dt.minus({month : 2}).toFormat("LLL"))
    setPrev3Month(dt.minus({month : 3}).toFormat("LLL"))

    const diff = dt.diff(startMonth, ["days"])

    if(diff.values.days < 7){
      setDaysInClass('beginMonth')
    }else{
      if(diff.values.days < 14){
        setDaysInClass('midMonth')
      }else{
        if(diff.values.days < 21){
          setDaysInClass('endMonth')
        }else{
          if(diff.values.days < 28){
            setDaysInClass('veryEndMonth')
          }
        }
      }
    }
  }, [])

  return (
    <div className={"StatsMonthsDisplay " + daysInClass}>

      <div>{prev3Month}</div>
      <div>{prev2Month}</div>
      <div>{prevMonth}</div>
      <div>{nowMonth}</div>

    </div>
  )
}

export default StatsMonthsDisplay