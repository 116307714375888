import PropTypes from 'prop-types'

function ProgressBar({ isActive }) {
  return (
    <div className={`ProgressBar${isActive ? ' is-active' : ''}`}>
      <div className="ProgressBar-bar"></div>
    </div>
  )
}

ProgressBar.propTypes = {
  isActive: PropTypes.bool.isRequired
}

export default ProgressBar