import React, {createRef, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import StarIcon from '@material-ui/icons/Star'
import LockIcon from '@material-ui/icons/Lock';
import CheckIcon from '@material-ui/icons/Check';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TimelineIcon from '@material-ui/icons/Timeline';

import TodoSummary from '../../commons/todo/TodoSummary'
import TodayRateModal from '../../commons/days/TodayRateModal'
import ProgressBar from '../../commons/ProgressBar'
import {DateTime} from "luxon";
import {getErrorMsgFromSymfonyResponse, getIcon} from "../../../helpers";
import {Link} from "react-router-dom";
import TodoAddModal from "../../commons/todo/TodoAddModal";
import daysAPI from "../../../api/daysAPI";
import withSnackbar from "../../wrappers/withSnackbar";
import todosAPI from "../../../api/todosAPI";
import { withRouter} from 'react-router-dom';

function Dashboard({snackMsg, history}) {

  // RATE MODAL
  const [rateModalOpen, setRateModalOpen] = useState(false)
  const [rateModalLoading, setRateModalLoading] = useState(false)
  const [rateTodayExisting, setRateTodayExisting] = useState(null)
  const [rateTodayButtonState, setRateTodayButtonState] = useState('')

  const [dt, setDt] = useState(DateTime.local())
  const [isProgressBarActive, setIsProgressBarActive] = useState(true)

  let menuItems = [
    {
      'name' : 'Stats',
      'slug' : 'stat',
      'shortdesc' : false,
      'url' : 'stats',
      'wip' : false
    },
    {
      'name' : 'Spendings',
      'slug' : 'spend',
      'shortdesc' : '35€ incoming',
      'wip' : true
    },
    {
      'name' : 'Projects',
      'slug' : 'project',
      'shortdesc' : false,
      'wip' : true
    },
    {
      'name' : 'Areas',
      'slug' : 'area',
      'shortdesc' : false,
      'wip' : true
    },

  ]

  useEffect(() => {
    updateCurrentDate()
    setIsProgressBarActive(false)
    daysAPI.getToday().then((resp) => {
      if(resp.data){
        setRateTodayExisting(resp.data)
      }else{
        setRateTodayExisting(false)
      }
    }).catch(error => {
      snackMsg('error', getErrorMsgFromSymfonyResponse(error.response))
    })

    let now = dt.setLocale('fr')
    let noon = dt.setLocale('fr').plus({ days: 1 }).set({ hour: 0, minute: 0, second: 0 })
    const diff = noon.diff(now, ["hours"])

    if (diff.toObject().hours < 2) {
      setRateTodayButtonState('urgent3')
    }else{
      if (diff.toObject().hours < 4) {
        setRateTodayButtonState('urgent2')
      }else{
        if (diff.toObject().hours < 6) {
          setRateTodayButtonState('urgent1')
        }
      }
    }


  }, [])

  const updateCurrentDate = function(){
    setTimeout(() => {
      setDt(DateTime.local())
      updateCurrentDate()
    }, 1000)
  }

  const rateModalSubmit = function (todayIsDrink, todayIsProd, todayIsSmoke, todayIsTHC, todayRatingScore, todayRestingScore) {
    setRateModalLoading(true)
    daysAPI.post({
      isDrinkDay : todayIsDrink,
      isProdDay : todayIsProd,
      isSmokeDay : todayIsSmoke,
      isThcDay : todayIsTHC,
      rating : todayRatingScore,
      tiring : todayRestingScore,
    }).then(resp => {
      setRateModalOpen(false)
      setRateModalLoading(false)
      setRateTodayExisting(resp.data)
      snackMsg('success', "Today's rating saved")
    }).catch(error => {
      snackMsg('error', getErrorMsgFromSymfonyResponse(error.response))
    })
  }

    return (
      <div className="Dashboard">
        <ProgressBar isActive={isProgressBarActive}/>

        <div className="Dashboard-head-time">
          <p>{dt.setLocale('fr').toFormat("cccc dd LLLL yyyy HH:mm:ss")}</p>

          {rateTodayExisting !== null &&
            <button className={"button is-small is-icon " + (rateTodayExisting ? 'disabled' : rateTodayButtonState)} onClick={() => {setRateModalOpen(true)}}>
              {rateTodayExisting ?
                <CheckIcon/>
                :
                <StarIcon/>
              }
            </button>
          }

        </div>

        <TodoSummary/>
        
        <div className="Dashboard-items">
          {menuItems.map((item, idx) => ( // can be transformed into component
            <div key={idx} className={"Dashboard-item card" + ' item_'+item.slug + (item.wip?' wip':'')} onClick={() => {if (!item.wip){history.push(item.url)}}} /*onclick redirects*/ >
              <div className="Dashboard-item-icon">{getIcon(item.slug)}</div>
              <div><p className="Dashboard-item-name">{item.name}</p></div>
              <div><p className="Dashboard-item-desc">{item.shortdesc}</p></div>
              {item.wip &&
                <p className="Dashboard-item-wip"><LockIcon/> WIP</p>
              }
            </div>
          ))}

        </div>

        <TodayRateModal
          rateModalOpen={rateModalOpen}
          setRateModalOpen={setRateModalOpen}
          rateModalLoading={rateModalLoading}
          rateModalSubmit={rateModalSubmit}
          rateTodayExisting={rateTodayExisting}
        />

      </div>
    )
}

Dashboard.propTypes = {
  user: PropTypes.object
}

export default withRouter(withSnackbar(Dashboard))

