import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import Header from '../../commons/Header'
import Menu from '../../commons/Menu'
import CustomerMenu from '../../commons/customer/CustomerMenu'
import Alerts from '../../commons/Alerts'
import DashboardContainer from '../dashboard/DashboardContainer'
import { getRandomColor, getRoleFromToken } from '../../../helpers'
import logoBg from '../../../assets/greenkub-bg.png'
import Todos from "../todos/Todos";
import Stats from "../stats/Stats";

class Home extends React.Component {
  user = {
    'email': this.props.email,
    'avatar': this.props.avatar,
    'name': this.props.user?this.props.user.firstName + ' ' + this.props.user.lastName:'',
  }

  constructor(props) {
    super(props)

    this.state = {
      isMenuOpened: false,
      role: false,
      alerts: [],
      intervalRef: null,
      customerProjectCurrentStep: 1
    }

    this.onToggleMenu = this.onToggleMenu.bind(this)
  }

  onToggleMenu() {
    this.setState({
      isMenuOpened: !this.state.isMenuOpened
    })
  }

  componentDidMount(){
    let intervalRef = null
    this.setState({
      role: getRoleFromToken(),
      intervalRef: intervalRef
    })
  }

  componentWillUnmount() {
    if (this.state.intervalRef !== null) {
      clearInterval(this.state.intervalRef)
    }
  }

  render() {
    return (
      <div
        className={`Home${this.state.isMenuOpened ? ' is-menu-opened' : ''}`}
        style={this.state.role === 'CUSTOMER' ? { backgroundImage: `url(${logoBg})` } : {}}
      >
        <Header
          user={this.user}
          role={this.state.role}
          logout={this.props.onLogout}
          onToggleMenu={this.onToggleMenu}
          customerProjectCurrentStep={this.state.customerProjectCurrentStep}
        />

        {/*{this.state.role === 'GK'*/}
        {/*    ? (*/}
              <>
                <Menu onToggleMenu={this.onToggleMenu}/>
                <Alerts alerts={this.state.alerts}/>
              </>
            {/*)*/}
            {/*: <CustomerMenu onToggleMenu={this.onToggleMenu}/>*/}
        {/*}*/}

        <main className={"Home-container" + (this.state.role === 'CUSTOMER'? ' role_customer' : '')}>

          {/*{this.state.role !== false && (this.state.role === 'GK' ?*/}
            <React.Fragment>
              <Switch>
                <Route exact path={'/'} component={DashboardContainer}/>
                <Route exact path={'/todos'} component={Todos}/>
                <Route exact path={'/stats'} component={Stats}/>

                {/*<Route exact path={'/documents'} render={() => <SaleDocumentList user={this.props.user}/>}/>*/}

                {/*<Route path={'/projets/:projectId/documents'} component={CustomerDocuments}/>*/}
                {/*<Route path={'/projets/:projectId/configs/:type?'} component={Configs}/>*/}
                {/*<Route exact path={'/projets/:projectId/:projectAction?'} render={(props) => (*/}
                {/*    <ProjectContainer user={this.props.user} key={props.match.params.projectId} {...props}/>*/}
                {/*)}/>*/}
                {/*<Route exact path={'/start/r1/:projectId'} component={R1Start}/>*/}
                {/*<Route exact path={'/analyse-plu/:projectId'} component={AnalysePlu}/>*/}
                {/*<Route exact path={'/welcome-call/:projectId'} component={WelcomeCall}/>*/}
              </Switch>
            </React.Fragment>
              {/*:*/}
            {/*<React.Fragment>*/}
              {/*<Route path={'/'} render={() => (*/}
              {/*  <CustomerDashboard*/}
              {/*    onUpdateProjectStepNum={customerProjectCurrentStep => this.setState({ customerProjectCurrentStep })}*/}
              {/*  />*/}
              {/*)}/>*/}
            {/*</React.Fragment>*/}
          {/*)}*/}
        </main>
      </div>
    )
  }
}

Home.propTypes = {
  email: PropTypes.string,
  onLogout: PropTypes.func,
  user: PropTypes.object,
}

export default Home

