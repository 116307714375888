import jwt_decode from "jwt-decode";
import {DateTime, Interval} from "luxon";
import usersAPI from "./api/usersAPI";
// import projectsAPI from "./api/projectsAPI";
import {useAsync} from "react-use";
// import equipmentsAPI from "./api/equipmentsAPI";

import EuroIcon from "@material-ui/icons/Euro";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import TimelineIcon from "@material-ui/icons/Timeline";
import AssignmentIcon from '@material-ui/icons/Assignment';
import AppsIcon from '@material-ui/icons/Apps';

import React from "react";

export function getIcon(name) {
  switch (name) {
    case 'spend':
      return (<EuroIcon/>)
    case 'project':
      return (<EmojiObjectsIcon/>)
    case 'area':
      return (<LocationOnIcon/>)
    case 'stat':
      return (<TimelineIcon/>)
    case 'todo':
      return (<AssignmentIcon/>)
    case 'dashboard':
      return (<AppsIcon/>)
  }
  return ''
}

export function getErrorMsgFromSymfonyResponse(resp) {
  if (resp === undefined) {
    return 'Une erreur est survenue'
  }

  if (resp.data.message === 'Validation Failed' && resp.data.errors) {
    for (let field in resp.data.errors.children) {
      if (resp.data.errors.children[field].errors && resp.data.errors.children[field].errors.length > 0) {
        return resp.data.errors.children[field].errors[0]
      }
    }

  } else if (resp.data.message !== undefined) {
    return resp.data.message
  }

  return 'Une erreur est survenue'
}

export function formatSymfonyValidationErrors(errors) {
  return Object.entries(errors.children)
    .filter(([key, value]) => 'errors' in value)
    .map(([key, value]) => [key, { message: value.errors[0] }])
}

export function getCSSHeight(element, withoutPadding=false) {
  const rect = element.getBoundingClientRect()

  if (withoutPadding) {
    const computedStyle = getComputedStyle(element)
    return rect.bottom - rect.top - parseInt(computedStyle.getPropertyValue('padding-top')) - parseInt(computedStyle.getPropertyValue('padding-bottom'))
  }

  return rect.bottom - rect.top
}

export function getCSSWidth(element, withoutPadding=false) {
  const rect = element.getBoundingClientRect()

  if (withoutPadding) {
    const computedStyle = getComputedStyle(element)
    return rect.right - rect.left - parseInt(computedStyle.getPropertyValue('padding-left')) - parseInt(computedStyle.getPropertyValue('padding-right'))
  }

  return rect.right - rect.left
}

export function fileToBase64(fileInput) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileInput);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  })
}

/**
 * @param {number} x
 * @param {boolean} showSign
 * @returns {string | number}
 */
export function formatCurrency(x, showSign) {
  if(x == null){
    return 0
  }
  const sign = showSign && x > 0 ? "+" : ""

  return sign + x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' €';
}
export function capitalize(str){
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getRoleFromToken() {
  let localAuth = localStorage.getItem('auth')
  localAuth = JSON.parse(localAuth)
  if (localAuth) {
    let decoded = jwt_decode(localAuth.token);
    if (decoded) {
      if (decoded.roles.includes('ROLE_GK') || decoded.roles.includes('ROLE_SUPER_ADMIN')) {
        return 'GK'
      }
      if (decoded.roles.includes('ROLE_CUSTOMER')) {
        return 'CUSTOMER'
      }
    }
  }
  return false;
}
